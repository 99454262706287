import dynamic from 'next/dynamic';

const PieChart = dynamic(
  () => import('@nivo/pie').then(nivo => nivo.ResponsivePie),
  {
    ssr: false,
  },
);

export default PieChart;
